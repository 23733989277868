import React from 'react'
import './eximDocumentation.css'

export default function EximDocumentation() {
  return (
    <>
      <h1>Exim Documentation</h1>
    </>
  )
}
