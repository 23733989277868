import React from 'react'
import './exportBuyersConnection.css'

export default function ExportBuyersConnection() {
  return (
    <>
      <h1>Export Buyers Connection</h1>
    </>
  )
}
