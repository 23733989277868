import React from 'react'
import './cultivation.css'

function Cultivation() {
  return (
    <div>
      <h4 className="text-center">Timeline of HTML</h4>
      <div className="mainOl">
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
        <div className="timelineCard">
          <p class="event-date">October 1991</p>
          <p class="event-description">
            HTML Tags, an informal CERN document listing 18 HTML tags, was first
            mentioned in public.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Cultivation
